import React, { useContext, useEffect, useRef } from "react";
import { API } from "aws-amplify";
// import Razorpay from "razorpay";
import InstitutionContext from "../../Context/InstitutionContext";
// import SubscriptionPopup from "../../pages/SubscriptionPopup";
import { useNavigate } from "react-router-dom";
import Context from "../../Context/Context";

const RazorpaySubscription = ({ productId }) => {
  const InstitutionData = useContext(InstitutionContext).institutionData;
  const razorpay = useRef();
  const UtilCtx = useContext(Context).util;
  const Ctx = useContext(Context);
  const UserCtx = useContext(Context).userData;

  const Navigate = useNavigate();

  useEffect(() => {
    // new window.Razorpay();
    console.log(window.razorpay);
  }, []);

  const handleSubscribe = async () => {
    UtilCtx.setLoader(true);
    let response;
    try {
      console.log("before");
      response = await API.put(
        "user",
        `/user/billing/subscription/${InstitutionData.institutionid}`,
        {
          body: {
            productId: productId,
          },
        }
      );
      console.log(response);
    } catch (e) {
      console.log(e);
      UtilCtx.setLoader(false);
    }
    console.log(response.paymentId);
    console.log("started");
    try {
      const options = {
        key:
          process.env.STAGE === "PROD"
            ? InstitutionData.razorpayKeyId
            : InstitutionData.src_Components_Home_Pricing__razorpayKeyId,
        subscription_id: response.paymentId,
        name: InstitutionData.institutionid.toUpperCase(),
        description: response.subscriptionType,
        image: `https://institution-utils.s3.amazonaws.com/${InstitutionData.institutionid}/images/happyprancer-green.png`,
        handler: function (r) {
          console.log(r);
          const verify = async () => {
            console.log("EARLY");
            UtilCtx.setLoader(true);
            try {
              const res = await API.put(
                "user",
                `/user/billing/subscription/verify/${InstitutionData.institutionid}`,
                {
                  body: {
                    subscriptionId: response.paymentId,
                  },
                }
              );
              const tempUserdata = await API.get(
                "user",
                `/user/profile/${InstitutionData.institutionid}`
              );
              Ctx.setUserData(tempUserdata);
              console.log(res);
              if (res.signatureIsValid) {
                console.log(res.signatureIsValid);
                Navigate("/dashboard", { state: { isReload: true } });
              } else {
                alert(
                  "Transaction Failed If your Amount was Deducted then Contact us"
                );
              }
              // alert(res);
              UtilCtx.setLoader(false);
            } catch (e) {
              console.log(e);
              UtilCtx.setLoader(false);
            }
          };
          verify();
        },
        prefill: {
          name: UserCtx.userName,
          email: UserCtx.emailId,
          contact: UserCtx.phoneNumber,
        },
        theme: {
          color: InstitutionData.primaryColor,
        },
      };
      console.log("started 2");
      const rzp1 = new window.Razorpay(options);
      console.log("started 3");
      rzp1.on("payment.failed", function (response) {
        // alert(response.error.code);
        // alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        // alert(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
        console.log(response);
        UtilCtx.setLoader(false);
      });
      const fields = rzp1.open();
      console.log(fields);
      UtilCtx.setLoader(false);
    } catch (e) {
      console.log(e.message);
      console.log(e);
      UtilCtx.setLoader(false);
    }
  };

  // const handleSubscribe = () => {
  //   Navigate("/subscribe");
  // };

  return (
    <div className="z-1">
      <button
        ref={razorpay}
        className={`w-[15rem] bg-primary2 text-white px-12 py-2 rounded-2xl hover:text-[#225c59] hover:bg-white hover:border-[#225c59] hover:border-[0.2rem] h-[3rem] flex justify-center items-center mt-16 max450:w-[60vw] cursor-pointer`}
        onClick={handleSubscribe}
      >
        Subscribe
      </button>
    </div>
  );
};

export default RazorpaySubscription;
