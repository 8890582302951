import React, { useContext } from "react";
import InstitutionContext from "../../Context/InstitutionContext";

const Header = () => {
  const InstitutionData = useContext(InstitutionContext).institutionData;

  return (
    <div className={` flex items-center justify-center h-[30rem] pb-20`}>
      <div
        className={`absolute z-10 flex flex-col items-center w-screen content `}
      >
        <div className={`w-[auto] text-left flex`}>
          <h1
            className={`w-full max1250:w-[50%] max536:w-[90vw] max800:w-[80%]  text-[5.7rem] max800:text-[3.8rem] max1250:text-[4.5rem `}
            style={{
              color: InstitutionData.src_Components_Home_Header__Text_Color_1,
            }}
          >
            Fitness at your fingertips
          </h1>
        </div>
      </div>
      <div className={`-z-10 `}>
        <video
          autoPlay
          loop
          muted
          playsInline={true}
          controls={false}
          className={`object-cover object-top h-[38rem] w-screen   max-w-screen`}
        >
          <source
            src={`https://institution-utils.s3.amazonaws.com/${InstitutionData.institutionid}/images/backgroundvideo.mp4`}
            type="video/mp4"
          />
        </video>
      </div>
    </div>
  );
};

export default Header;
