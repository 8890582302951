import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { ContextProvider } from "./Context/ContextProvider";
import "./index.css";
// import 'react-phone-input-2/lib/material.css'
import App from "./App";
import { InstitutionContextProvider } from "./Context/InstitutionContextProvider";

// console.log(process.env);

// const process = {
//   env: {
//     STAGE: "DEV",
//   },
// };

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: "us-east-2",
    userPoolId:
      process.env.STAGE === "PROD"
        ? "us-east-2_9joxeJ5EP"
        : "us-east-2_uwdNo4K2o",
    // : "us-east-2_bmR0JfjSg",
    // : "us-east-2_JZ7ZDYbs8",
    // : "us-east-2_9OhXG0Jjk",
    identityPoolId:
      process.env.STAGE === "PROD"
        ? "us-east-2:fcc57a23-9e12-4bd0-9266-3fa44581bebe"
        : "us-east-2:6a989bd3-6905-4c5d-b2ea-6101ccfbedd3",
    // : "us-east-2:ad60de0e-2fd9-4190-9e7b-49e2e61f5155",
    // : "us-east-2:b31ba093-deb4-47ea-9515-e0caff32a4e8",
    // : "us-east-2:a841ce16-79ab-44ca-be5f-ceee7793c943",
    userPoolWebClientId:
      process.env.STAGE === "PROD"
        ? "5ej5844tf42rf77slua0a8q9o3"
        : "21hf8if0vuceksuds2o42ahsa3",
    // : "66j6s7v7obqtj6lb2eeovdiv7e",
    // : "1rr77q6iqug92tqjrt9qjep938",
    // : "6hfb7jtfp684mls0v85blsrik",
    oauth: {
      // domain: `${"gymnaism-harshnew-auth-domain.auth.ap-south-1.amazoncognito.com"}`,
      // scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
      // redirectSignIn: "http://localhost:3000",
      // redirectSignOut: "http://localhost:3000",
      // redirectSignIn: "https://gymnaism.netlify.app",
      // redirectSignOut: "https://gymnaism.netlify.app",
      responseType: "token",
    },
  },
  Storage: {
    region: "us-east-1",
    bucket: "userprofilepict",
    identityPoolId:
      process.env.STAGE === "PROD"
        ? "us-east-2:fcc57a23-9e12-4bd0-9266-3fa44581bebe"
        : "us-east-2:6a989bd3-6905-4c5d-b2ea-6101ccfbedd3",
  },
  API: {
    endpoints: [
      {
        name: "user",
        endpoint:
          process.env.STAGE === "PROD"
            ? "https://7sh8wrjmm2.execute-api.us-east-1.amazonaws.com/dev"
            : "https://r5dp21mb28.execute-api.us-east-2.amazonaws.com/dev",
        region: process.env.STAGE === "PROD" ? "us-east-1" : "us-east-2",
      },
      {
        name: "institution",
        endpoint: "https://7sh8wrjmm2.execute-api.us-east-1.amazonaws.com/dev",
        region: "us-east-1",
      },
    ],
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <InstitutionContextProvider>
      <ContextProvider>
        <App />
      </ContextProvider>
    </InstitutionContextProvider>
  </BrowserRouter>
);
