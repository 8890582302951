import React, { useContext } from "react";
import { useNavigate } from "react-router";
// import "../comp/Subscription.css";
import Context from "../Context/Context";
import HappyprancerPaypalMonthly from "../Components/Subscription/HappyprancerPaypalMonthly";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Home/Footer";
import HappyprancerPaypalHybrid from "../Components/Subscription/HappyprancerPaypalHybrid";
import InstitutionContext from "../Context/InstitutionContext";
import RazorpaySubscription from "../Components/Subscription/RazorpaySubscription";

export default function Subscription() {
  const InstitutionData = useContext(InstitutionContext).institutionData;
  const text = {
    Heading: "Monthly Membership Subscription",
    sub_Heading: "see what are the pricing in details",
  };
  const Ctx = useContext(Context);
  const UserCtx = useContext(Context).userData;

  const Navigate = useNavigate();

  const paymentHandler = (item) => {
    if (item.currency === "INR" && item.subscriptionType === "Monthly") {
      return <RazorpaySubscription productId={item.productId} />;
    }
    if (item.currency === "USD" && item.subscriptionType === "Monthly") {
      return <HappyprancerPaypalMonthly />;
    }
    if (item.currency === "USD" && item.subscriptionType === "Hybrid") {
      return <HappyprancerPaypalHybrid />;
    }
  };

  console.log(InstitutionData.src_pages_Subscription__BgColor_Parent);

  return (
    <>
      <NavBar />
      <div
        className={`Back text-[1.5rem]  flex  flex-col items-center h-auto min-h-screen max980:h-[auto] justify-center gap-[5rem] pb-20`}
        style={{
          backgroundColor: `${InstitutionData.src_pages_Subscription__BgColor_Parent}`,
        }}
      >
        <div className={`text-center mt-[7rem] sans-sarif `}>
          <h1>{text.Heading}</h1>
          <h3 className={`text-[1rem]`}>{text.sub_Heading}</h3>
        </div>
        <ul
          className={`flex flex-wrap justify-center w-[90vw] max-w-[80rem] gap-28 pl-0`}
        >
          {Ctx.productList.map((item) => {
            return (
              <li
                className={`w-[24rem]  py-16 px-8 rounded-[2rem] z-10 flex flex-col items-center gap-8 shadowSubscribe   max450:w-[90vw] max450:gap-4 max450:text-[1rem] max450:min-h-[28rem] max450:h-auto max450:p-12 border-primary2 border-[0.1rem]`}
                style={{
                  backgroundColor: `${InstitutionData.src_pages_Subscription__BgColor_Card}`,
                }}
              >
                <p className={`text-[1.6rem]`}>{item.heading}</p>
                <ul className={` text-[1rem] h-auto pl-0 flex flex-col`}>
                  {item.provides.map((item) => {
                    return (
                      <li>
                        <p>{item}</p>
                      </li>
                    );
                  })}
                </ul>
                <h1 className={`text-left w-[100%]`}>
                  {(item.currency === "INR" ? "₹ " : "$ ") +
                    parseInt(item.amount) / 100 +
                    "/" +
                    item.durationText}
                </h1>
                {Ctx.isAuth ? (
                  <div className={` `}>
                    {UserCtx.status === "Active" ? (
                      <p
                        className={`text-[1rem] w-[15rem] px-12 py-2 rounded-2xl text-primary2  border-primary2 border-[0.2rem] h-[3rem] flex justify-center items-center mt-16 max450:w-[60vw]`}
                      >
                        Already Subscribed
                      </p>
                    ) : (
                      <>{paymentHandler(item)} </>
                    )}
                  </div>
                ) : (
                  <button
                    onClick={() => {
                      Navigate("/signup");
                    }}
                    className={`w-[15rem] bg-primary2 px-12 py-2 rounded-2xl hover:text-primary2 hover:bg- hover:border-primary2 hover:border-[0.3rem] h-[3rem] flex justify-center items-center mt-16 max450:w-[60vw]`}
                    style={{
                      color: `${InstitutionData.src_pages_Subscription__Text_Button}`,
                    }}
                  >
                    Sign Up
                  </button>
                )}
              </li>
            );
          })}
        </ul>
      </div>
      <Footer />
    </>
  );
}
