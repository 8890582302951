import { useContext, useEffect, useRef } from "react";
import { Auth, API } from "aws-amplify";
import Context from "./Context/Context";
import RoutesContainer from "./Routes";
import LoaderProvider from "./Components/LoaderProvider";
import InstitutionContext from "./Context/InstitutionContext";

function App() {
  const UtilCtx = useRef(useContext(Context).util);
  const RefCtx = useRef(useContext(Context));
  const RefInstitutionCtx = useRef(useContext(InstitutionContext));
  const InsitutionCtx = useContext(InstitutionContext);

  useEffect(() => {
    const dataLoadFn = async () => {
      try {
        let data = require("./Utils/Data/data.json");
        // data = await API.get(
        //   "institution",
        //   `/any/get-institution-data/${data && data.institutionid}`
        // );

        RefInstitutionCtx.current.setInstitutionData(data);
        RefCtx.current.onUnauthLoad(data.institutionid);

        await check(data);
      } catch (e) {
        console.log(e);
      }
    };

    const check = async (data) => {
      UtilCtx.current.setLoader(true);

      try {
        await Auth.currentAuthenticatedUser();
        console.log("Starting");
        const userdata = await API.get(
          "user",
          `/user/profile/${data && data.institutionid}`
        );

        // userdata.Status = true;
        RefCtx.current.setUserData(userdata);
        RefCtx.current.setIsAuth(true);
        UtilCtx.current.setLoader(false);
        RefCtx.current.onAuthLoad(true, data.institutionid);
      } catch (e) {
        console.log(e);
        RefCtx.current.setUserData({});
        UtilCtx.current.setLoader(false);
      }
    };

    dataLoadFn();
  }, []);

  return (
    <LoaderProvider>
      {InsitutionCtx.institutionData && <RoutesContainer />}
    </LoaderProvider>
  );
}

export default App;
