import React, { useState, useContext } from "react";
import Context from "../../Context/Context";
import Pagination from "@mui/material/Pagination";
import { API } from "aws-amplify";
import "./UpcomingSessions.css";
import Button1 from "../Utils/Buttons/Button1";
import Streak from "../DashBoard/Streak";
import InstitutionContext from "../../Context/InstitutionContext";
import { onJoinClass } from "../DashBoard/StreakFunctions";
// import { useNavigate } from "react-router-dom";

const UpcomingSessionsMobile = () => {
  const InstitutionData = useContext(InstitutionContext).institutionData;
  // const unpaidUser = {
  //     text: 'You need a subscription to access the Upcoming classes.',
  // }
  // const Navigate = useNavigate();
  const Ctx = useContext(Context);
  const UtilCtx = useContext(Context).util;
  const [date, setDate] = useState("");

  const [showScheduleForm, setShowScheduleForm] = useState(false);
  const [scheduleFormValues, setScheduleFormValues] = useState({
    instructor: "",
    date: "",
    time: "",
    class: "",
    zoomLink: "",
  });

  // eslint-disable-next-line
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setScheduleFormValues({
      ...scheduleFormValues,
      [name]: value,
    });
  };

  // eslint-disable-next-line
  const [due, setDue] = useState(0);

  const [classType, setClassType] = useState("");
  const [zoomLink, setZoomLink] = useState("");
  const [selectedInstructor, setselectedInstructor] = useState("");
  const itemsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(Ctx.upcomingClasses.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  // eslint-disable-next-line
  const [editingIndex, setEditingIndex] = useState(-1);
  const instructorNamesArray = Ctx.instructorList;
  const classTypeNameArray = InstitutionData.classTypes;
  const [count, setCount] = useState(0);

  const formatDate = (epochDate) => {
    const date = new Date(parseInt(epochDate));
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = (hours % 12 || 12).toString().padStart(2, "0");
    return `${formattedHours}:${minutes} ${period}`;
  };
  const formatdate = (epochDate) => {
    const date = new Date(epochDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed, so we add 1 to get the correct month
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const UserCtx = useContext(Context);

  const isMember = UserCtx.userData.userType === "member";

  const getInstructor = (cognitoId) => {
    return Ctx.instructorList.find(
      (i) => i.instructorId?.toString() === cognitoId?.toString()
    );
  };

  const onClassUpdated = async (
    classId,
    editedInstructorNames,
    instructorId,
    editedClassType
  ) => {
    UtilCtx.setLoader(true);

    try {
      if (!instructorId) {
        alert("Please select an instructor.");
        UtilCtx.setLoader(false);
        return;
      }

      if (!editedClassType) {
        alert("Please select an Class Type.");
        UtilCtx.setLoader(false);
        return;
      }

      const updatedClasses = Ctx.upcomingClasses.map((c) =>
        c.classId === classId
          ? {
              ...c,
              instructorNames: editedInstructorNames,
              instructorId,
              classType: editedClassType,
            }
          : c
      );
      await API.put(
        "user",
        `/admin/edit-schedule-name/${InstitutionData.institutionid}`,
        {
          body: {
            classId: classId,
            instructorNames: editedInstructorNames,
            instructorId,
            classType: editedClassType,
          },
        }
      );

      Ctx.setUpcomingClasses(updatedClasses);

      setEditingIndex(-1);

      UtilCtx.setLoader(false);
    } catch (e) {
      alert(e.message);
      UtilCtx.setLoader(false);
    }
  };

  const onScheduleCreate = async (e) => {
    e.preventDefault();

    try {
      UtilCtx.setLoader(true);

      if (!classType || !selectedInstructor.name || !zoomLink || !date) {
        alert("Please fill in all sections.");
        return;
      }

      try {
        new URL(zoomLink);
      } catch (error) {
        alert("Invalid Zoom link. Please enter a valid URL.");
        UtilCtx.setLoader(false);
        return;
      }

      await API.post(
        "user",
        `/admin/add-schedule/${InstitutionData.institutionid}`,
        {
          body: {
            classType: classType,
            startTimeEst: new Date(date).getTime(),
            instructorEmailId: Ctx.userData.emailId,
            duration: 600,
            instructorId: selectedInstructor.instructorId,
            instructorNames: selectedInstructor.name,
            classDescription: "",
            zoomLink: zoomLink,
            date: new Date(date).getTime(),
          },
        }
      );
      try {
        new URL(zoomLink);
      } catch (error) {
        alert("Invalid Zoom link. Please enter a valid URL.");
        UtilCtx.setLoader(false);
        return;
      }

      alert("Class Added");

      const newClass = {
        classType: classType,
        startTimeEst: new Date(date).getTime(),
        instructorEmailId: Ctx.userData.emailId,
        duration: 600,
        instructorId: selectedInstructor.instructorId,
        instructorNames: selectedInstructor.name,
        classDescription: "",
        zoomLink: zoomLink,
        date: new Date(date).getTime(),
      };
      Ctx.setUpcomingClasses([...Ctx.upcomingClasses, newClass]);

      setClassType("");
      setselectedInstructor("");
      setZoomLink("");
      setDate("");
    } catch (error) {
      alert(error.message);
    } finally {
      UtilCtx.setLoader(false);
    }
  };

  // Sort the upcoming classes based on the date in descending order
  const sortedUpcomingClasses = Ctx.upcomingClasses.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );

  return (
    <div className={`w-full px-2 pb-4`}>
      {(Ctx.userData.userType === "admin" ||
        Ctx.userData.userType === "instructor") && (
        <div className={`container`}>
          <button
            className={`filter-button w-full m-[1rem] h-[2.1rem] rounded-[0.3rem] text-snow`}
            style={{
              backgroundColor:
                InstitutionData.src_Components_DashboardMobile__li_button_bg,
              color:
                InstitutionData.src_Components_DashboardMobile__li_button_color,
            }}
            onClick={() => setShowScheduleForm(!showScheduleForm)}
          >
            CREATE A NEW SESSION
          </button>
        </div>
      )}
      <div>
        {isMember && (
          <div
            className={` w-[90%] m-[1rem] pt-[1rem] h-[2.1rem] rounded-[0.3rem] text-snow flex items-center justify-center`}
            style={{
              backgroundColor:
                InstitutionData.src_Components_DashboardMobile__li_button_bg,
              color:
                InstitutionData.src_Components_DashboardMobile__li_button_color,
            }}
          >
            <div className={`flex`}>
              <p className={`pr-3`}>
                Attendance:{"  "}
                <span style={{ color: "green" }}>
                  {UserCtx.userData.currentMonthZPoints
                    ? UserCtx.userData.currentMonthZPoints
                    : 0}
                </span>{" "}
                /{" "}
                <span style={{ color: "red" }}>
                  {UserCtx.userData.lastMonthZPoint
                    ? UserCtx.userData.lastMonthZPoint
                    : 0}
                </span>
              </p>
            </div>
            <div className={`flex ml-4`}>
              <p>{`Due: ${UserCtx.userData.balance || 0}`}</p>
            </div>
          </div>
        )}

        {showScheduleForm && (
          <form className={`flex flex-col gap-6 w-full Sansita`}>
            <div
              className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-[0.5rem]`}
            >
              <div className="">
                <select
                  className="input-field w-full h-8"
                  value={classType}
                  onChange={(e) => setClassType(e.target.value)}
                >
                  <option value="">Select Class Type</option>
                  {classTypeNameArray.map((classType) => (
                    <option key={classType} value={classType}>
                      {classType}
                    </option>
                  ))}
                </select>
                {/* <div className="dropdown-arrow"></div> */}
              </div>
              <div className="">
                <select
                  className="input-field w-full h-8"
                  value={selectedInstructor.instructorId}
                  onChange={(e) => {
                    setselectedInstructor(getInstructor(e.target.value));
                  }}
                >
                  <option value="">Select Instructor</option>
                  {instructorNamesArray.map((i) => (
                    <option key={i.instructorId} value={i.instructorId}>
                      {i.name}
                    </option>
                  ))}
                </select>
                {/* <div className="dropdown-arrow"></div> */}
              </div>
              <input
                placeholder="Zoom Link"
                className={`input-field`}
                value={zoomLink}
                onChange={(e) => setZoomLink(e.target.value)}
              />
              <input
                placeholder="Date"
                type="datetime-local"
                name="date"
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                }}
                className={`input-field`}
              />
            </div>
            <Button1 data={"POST"} fn={onScheduleCreate} h={"2.4rem"} />
          </form>
        )}

        <h2
          className={`text-[1.4rem] mb-5 font-bold text-black-700 mt-5 text-center`}
        >
          Upcoming Sessions
        </h2>
        <Streak count={count} setCount={setCount} />
        <div
          className={`grid gap-[1.4rem] md:gap-4 grid-cols-1 sm:grid-cols-2`}
        >
          {sortedUpcomingClasses.slice(startIndex, endIndex).map((clas, i) => (
            <div key={clas.classId} className={`class-container`}>
              <div
                className={`rounded-lg p-3 md:p-4 shadow-md`}
                style={{
                  background:
                    InstitutionData.src_Components_DashboardMobile__li_bg,
                  boxShadow: "0 0px 15px rgba(0, 0, 0, 0.4)",
                  borderRadius: "1.8rem",
                }}
              >
                <div className={`flex items-center justify-between`}>
                  <div>
                    <div className={`w-[7rem]  attractive-dropdown-container`}>
                      {/* Show the dropdowns only to admin and instructor users */}
                      {Ctx.userData.userType === "admin" ||
                      Ctx.userData.userType === "instructor" ? (
                        <div className={`dropdown-wrapper`}>
                          <select
                            className={`rounded-[0.51rem] px-1 attractive-dropdown`}
                            style={{
                              backgroundColor:
                                InstitutionData.src_Components_DashboardMobile__li_select,
                            }}
                            value={
                              getInstructor(clas.instructorId)?.instructorId
                            }
                            onChange={(e) => {
                              onClassUpdated(
                                clas.classId,
                                getInstructor(clas.instructorId).name,
                                e.target.value,
                                clas.classId
                              );
                            }}
                          >
                            {instructorNamesArray.map((i) => (
                              <option key={i.congnitoId} value={i.instructorId}>
                                {i.name}
                              </option>
                            ))}
                          </select>
                          <div className={`dropdown-arrow`}></div>
                        </div>
                      ) : (
                        <p
                          className={`rounded-[0.51rem] pr-4 max600:bg-[#09edb900] `}
                          style={{
                            backgroundColor:
                              InstitutionData.src_Components_DashboardMobile__li_select,
                          }}
                        >
                          {clas.instructorNames}
                        </p>
                      )}

                      {Ctx.userData.userType === "admin" ||
                      Ctx.userData.userType === "instructor" ? (
                        <div className={`dropdown-wrapper2`}>
                          <select
                            className={`rounded-[0.51rem] px-1 attractive-dropdown2`}
                            style={{
                              backgroundColor:
                                InstitutionData.src_Components_DashboardMobile__li_select,
                            }}
                            value={clas.classType}
                            onChange={(e) => {
                              onClassUpdated(
                                clas.classId,
                                getInstructor(clas.instructorId)?.name,
                                clas.instructorId,
                                e.target.value
                              );
                            }}
                          >
                            {classTypeNameArray.map((classType) => (
                              <option key={classType} value={classType}>
                                {classType}
                              </option>
                            ))}
                          </select>
                          <div className={`dropdown-arrow2`}></div>
                        </div>
                      ) : (
                        <p
                          className={`rounded-[0.51rem] max600:bg-[#09edb900] `}
                          style={{
                            backgroundColor:
                              InstitutionData.src_Components_DashboardMobile__li_select,
                          }}
                        >
                          {clas.classType}
                        </p>
                      )}
                    </div>

                    <div className={`mb-1 mt-1`}>
                      Date: {formatdate(parseInt(clas.date))}
                    </div>
                    <div>Time: {formatDate(clas.date)}</div>
                  </div>
                  <div className={`ml-2`}>
                    <button
                      className={`sans-sarif px-4 py-[4px] rounded-lg`}
                      style={{
                        borderRadius: "1rem",
                        backgroundColor:
                          InstitutionData.src_Components_DashboardMobile__li_button_bg,
                        color:
                          InstitutionData.src_Components_DashboardMobile__li_button_color,
                      }}
                      onClick={() => {
                        window.open(clas.zoomLink, "_blank");
                        onJoinClass(InstitutionData.institutionid);
                      }}
                    >
                      Join
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div
          className={`flex mb-[6rem] justify-center items-center mt-4 md:mt-6`}
        >
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={(event, value) => setCurrentPage(value)}
          />
        </div>
      </div>
      {/* } */}
    </div>
  );
};

export default UpcomingSessionsMobile;
