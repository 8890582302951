import React, { useState, useContext } from "react";
import Pagination from "@mui/material/Pagination";
import Context from "../../Context/Context";
// import "./DashboardRating.css";

export default function DashboardRatingMobile() {
  const Ctx = useContext(Context);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;

  const totalPages = Math.ceil(Ctx.ratings.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return (
    <div>
      <h2 className="text-[1.4rem] mb-5 font-bold text-black-700 mt-3 text-center">
        Reviews
      </h2>
      <div className="grid ">
        {Ctx.ratings.slice(startIndex, endIndex).map((rating) => (
          <div key={rating.ratingId} className="class-container">
            <div
              className="bg-gradient-to-r from-primary1  to-accent1 rounded-lg p-3 md:p-4 mx-2 shadow-md my-2"
              style={{
                background: `linear-gradient(to bottom,rgb(0 255 196), rgb(26 203 164))`,
                boxShadow: "0 0px 15px rgba(0, 0, 0, 0.4)",
                borderRadius: "1.8rem",
              }}
            >
              <div
                className="flex flex-row mx-4"
                style={{
                  justifyContent: "space-between",
                }}
              >
                <div className="w-[7rem] grid">
                  <div className="rounded-[0.51rem] px-2 py-1 font-bold">
                    {rating.userName.split(" ")[0]}
                  </div>
                  <div className="rounded-[0.51rem] px-2 py-1">
                    {" "}
                    {new Date(parseInt(rating.timestamp)).toLocaleDateString()}
                  </div>
                  <div className="rounded-[0.51rem] px-2 py-1">
                    {" "}
                    {new Date(parseInt(rating.timestamp)).toLocaleTimeString()}
                  </div>
                </div>
                <div className=" flex flex-col">
                  <div className="rounded-[0.51rem] px-2 text-center py-1 font-bold">
                    {" "}
                    {rating.instructorName}
                  </div>
                  <div className="rounded-[0.51rem] px-2 py-1">
                    {" "}
                    {Array.from({ length: 5 }, (_, index) => (
                      <span
                        key={index}
                        className={`text-2xl mx-1 ${
                          index < rating.rating
                            ? "text-yellow-500"
                            : "text-gray-300"
                        }`}
                      >
                        ★
                      </span>
                    ))}
                  </div>
                  <div className="rounded-[0.51rem] px-2 text-center py-1">
                    {" "}
                    {rating.review}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex mb-[6rem] justify-center items-center w-full">
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(event, value) => setCurrentPage(value)}
          style={{ margin: "0 auto" }}
        />
      </div>
    </div>
  );
}
