import React, { useContext } from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Home/Footer";
import "./Refund.css";
import InstitutionContext from "../Context/InstitutionContext";
const Terms = () => {
  const InstitutionData = useContext(InstitutionContext).institutionData;

  return (
    <>
      <div className={`flex flex-col items-center w-full pb-[5rem]`}>
        <NavBar />
        <div
          className={` text-justify flex flex-col items-center  w-100 h-100 mt-[2rem] p-0 overflow-x-hidden w-[90vw] max-w-[80rem]`}
        >
          <h1 className={` nor  text-center font-bebas-neue `}>
            {InstitutionData.src_pages_Refund__Heading_1}
          </h1>
          <p className={`mt-8 sm:ml-0 ml-5 mr-5`}>
            {InstitutionData.src_pages_Refund__Content_1}
          </p>
          <h4
            className={`text-[1.2rem] max450:text-[1rem] text-left mt-8 font-bold w-full sm:ml-0 ml-5 `}
          >
            {InstitutionData.src_pages_Refund__Heading_2}
          </h4>
          <p className={`mt-8 sm:ml-0 ml-5 mr-5`}>
            {InstitutionData.src_pages_Refund__Content_2}
          </p>
          <h4
            className={`text-[1.2rem] max450:text-[1rem] text-left mt-8 font-bold w-full sm:ml-0 ml-5 `}
          >
            {InstitutionData.src_pages_Refund__Heading_3}
          </h4>
          <p className={`mt-8 sm:ml-0 ml-5 mr-5`}>
            {InstitutionData.src_pages_Refund__Content_3}
          </p>

          <h4
            className={`text-[1.2rem] max450:text-[1rem] text-left mt-8 font-bold w-full sm:ml-0 ml-5 `}
          >
            {InstitutionData.src_pages_Refund__Heading_4}
          </h4>
          <p className={`mt-2 sm:ml-0 ml-5 mr-5`}>
            {InstitutionData.src_pages_Refund__Heading_4_Content_1}
          </p>
          <p className={`mt-2 sm:ml-0 ml-5 mr-5`}>
            {InstitutionData.src_pages_Refund__Heading_4_Content_2}
          </p>

          <p className={`mt-2 sm:ml-0 ml-5 mr-5`}>
            {InstitutionData.src_pages_Refund__Heading_4_Content_3}
          </p>
          <h4
            className={`text-[1.2rem] max450:text-[1rem] text-left mt-8 font-bold w-full sm:ml-0 ml-5  `}
          >
            {InstitutionData.src_pages_Refund__Heading_5}
          </h4>
          <p className={`mt-2 sm:ml-0 ml-5 mr-5`}>
            {InstitutionData.src_pages_Refund__Content_5}
          </p>
          <h4
            className={`text-[1.2rem] max450:text-[1rem] text-left mt-8 font-bold w-full sm:ml-0 ml-5 `}
          >
            {InstitutionData.src_pages_Refund__Heading_6}
          </h4>
          <p className={`mt-2 sm:ml-0 ml-5 mr-5`}>
            {InstitutionData.src_pages_Refund__Content_6}
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
