import React, { useContext } from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Home/Footer";
import InstitutionContext from "../Context/InstitutionContext";

const PrivacyPolicy = () => {
  const InstitutionData = useContext(InstitutionContext).institutionData;

  return (
    <div className={`flex flex-col items-center`}>
      <NavBar />
      <h1 className={`p-10 `}>Privacy Policy</h1>
      <div className={`w-[82vw] `}>
        {InstitutionData.src_pages_PrivacyPolicy__Content_1}
        <br />
        <br />
        {InstitutionData.src_pages_PrivacyPolicy__Content_2}
        <br />
        <br />
        Acknowledgement -
        <li>
          {InstitutionData.src_pages_PrivacyPolicy__Content_Acknowledgement_1}
        </li>
        <li>
          {InstitutionData.src_pages_PrivacyPolicy__Content_Acknowledgement_2}
        </li>
        <li>
          {InstitutionData.src_pages_PrivacyPolicy__Content_Acknowledgement_3}
        </li>
        <li>
          {InstitutionData.src_pages_PrivacyPolicy__Content_Acknowledgement_4}
        </li>
        <br />
        {InstitutionData.src_pages_PrivacyPolicy__Content_Acknowledgement_5}
        <br />
        <br />
        {InstitutionData.src_pages_PrivacyPolicy__Content_Acknowledgement_6}
        time.
        <br />
        <br />
        {InstitutionData.src_pages_PrivacyPolicy__Content_Text_1}
        <br />
        <br />
        {InstitutionData.src_pages_PrivacyPolicy__Content_Text_2}
        <br />
        <br />
        {InstitutionData.src_pages_PrivacyPolicy__Content_Acknowledgement_7}
        <br />
        <br />
        {InstitutionData.src_pages_PrivacyPolicy__Content_Acknowledgement_8}
        <br />
        <br />
        {InstitutionData.src_pages_PrivacyPolicy__Content_Acknowledgement_9}
        <hr />
        <em>
          {InstitutionData.src_pages_PrivacyPolicy__Content_Acknowledgement_10}
        </em>
        <br />
      </div>
      <div className={`mt-4`}>
        <Footer />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
