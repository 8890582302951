import React, { useState, useContext } from "react";
import Pagination from "@mui/material/Pagination";
import { useMediaQuery } from "@mui/material";
import DashboardRatingMobile from "../DashBordMobile/DashboardRatingMobile";
import Context from "../../Context/Context";

export default function DashboardRating() {
  const Ctx = useContext(Context);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;

  const isMobileScreen = useMediaQuery("(max-width: 600px)");
  const totalPages = Math.ceil(Ctx.ratings.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return (
    <>
      {!isMobileScreen && (
        <div className="h-[100vh] ml-[6rem] max1050:ml-[0] max1050:mt-0">
          <div
            className={`w-[85%] min-h-[35rem] bg-accent1 max536:bg-transparent max536:w-[100%] rounded-3xl p-2 flex flex-col items-center max1050:w-[94vw] mx-[2.5%] max1440:w-[95%] mt-10`}
          >
            <h2 className="pl-5 font-sans text-[1.4rem] max536:mb-3 max536:text-[1.7rem] sans-serif max536:text-[bg-[#1b7571]] font-bold">
              Reviews
            </h2>
            <div className="overflow-x-auto w-full">
              <ul className="relative px-0 pb-[3rem] w-[95%] max-w-[1700px] mx-auto flex flex-col max536:bg-primary1 rounded-3xl items-center justify-start pt-6 max536:gap-3 max536:h-[calc(100vh-16rem)] max536:bg-gradient-to-b max536:from-[#dad7c6] max536:to-[#fdd00891]">
                <li className="w-full flex flex-col items-center justify-center p-2 max536:pt-5 max536:rounded-2xl">
                  <div className="grid grid-cols-12 justify-content-between w-[98%] max1050:w-[100%] mb-5 font-bold">
                    <div className="col-span-2 text-center">User Name</div>
                    <div className="col-span-2 text-center">Instructor</div>
                    <div className="col-span-2 text-center">Date</div>
                    <div className="col-span-2 text-center">Time</div>
                    <div className="col-span-2 text-center">Rating</div>
                    <div className="col-span-2 text-center">Review</div>
                  </div>
                  {Ctx.ratings.slice(startIndex, endIndex).map((rating) => (
                    <div
                      key={rating.ratingId}
                      className="grid grid-cols-12 justify-content-between w-[98%] max1050:w-[100%] mb-4"
                    >
                      <div className="col-span-2 text-center">
                        {rating.userName.split(" ")[0]}
                      </div>
                      <div className="col-span-2 text-center">
                        {rating.instructorName}
                      </div>
                      <div className="col-span-2 text-center">
                        {new Date(
                          parseInt(rating.timestamp)
                        ).toLocaleDateString()}
                      </div>
                      <div className="col-span-2 text-center">
                        {new Date(
                          parseInt(rating.timestamp)
                        ).toLocaleTimeString()}
                      </div>
                      <div className="col-span-2 text-center">
                        {/* Integrate the StarRating component here with Tailwind classes */}
                        <div className="flex items-center justify-center">
                          {Array.from({ length: 5 }, (_, index) => (
                            <span
                              key={index}
                              className={`text-2xl mx-1 ${
                                index < rating.rating
                                  ? "text-yellow-500"
                                  : "text-gray-300"
                              }`}
                            >
                              ★
                            </span>
                          ))}
                        </div>
                      </div>
                      <div className="col-span-2 text-center">
                        {rating.review}
                      </div>
                    </div>
                  ))}
                </li>
              </ul>
            </div>
            <div className="absolute bottom-5 flex justify-center items-center w-full">
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={(event, value) => setCurrentPage(value)}
                style={{ margin: "0 auto" }}
              />
            </div>
          </div>
        </div>
      )}
      {isMobileScreen && <DashboardRatingMobile />}
    </>
  );
}
