import React, { useContext } from "react";
import InstitutionContext from "../../Context/InstitutionContext";

const Header3 = () => {
  const InstitutionData = useContext(InstitutionContext).institutionData;

  return (
    <div
      className={`flex justify-center flex-col items-center min-h-[50vh] gap-5 max850:h-auto py-20 `}
    >
      <h3
        className={`font-semibold text-[1.2rem]`}
        style={{
          color: InstitutionData.src_Components_Home_Header3__Text_1,
        }}
      >
        {InstitutionData.src_Components_Home_Header3__h1}
      </h3>
      <h4
        className={`font-semibold text-[2.1rem]`}
        style={{
          color: InstitutionData.src_Components_Home_Header3__Text_2,
        }}
      >
        {InstitutionData.src_Components_Home_Header3__h2}
      </h4>
      <div>
        <ul className={`flex justify-center flex-wrap items-center gap-10`}>
          <li className={`flex flex-col items-center w-[20rem]`}>
            <img
              src={`https://institution-utils.s3.amazonaws.com/${InstitutionData.institutionid}/images/section1-items/section-1-item-1.png`}
              alt="image1"
              className={`w-[110px] h-[80px] object-contain`}
            />
            <h5>{InstitutionData.src_Components_Home_Header3__h5_1}</h5>
            <p className={`text-center`}>
              {InstitutionData.src_Components_Home_Header3__p_1}
            </p>
          </li>
          <li className={`flex flex-col items-center w-[20rem] `}>
            <img
              src={`https://institution-utils.s3.amazonaws.com/${InstitutionData.institutionid}/images/section1-items/section-1-item-2.png`}
              alt="image2"
              className={`w-[80px] h-[80px] object-contain`}
            />
            <h5>{InstitutionData.src_Components_Home_Header3__h5_2}</h5>
            <p className={`text-center`}>
              {InstitutionData.src_Components_Home_Header3__p_2}
            </p>
          </li>
          <li className={`flex flex-col items-center w-[20rem] `}>
            <img
              src={`https://institution-utils.s3.amazonaws.com/${InstitutionData.institutionid}/images/section1-items/section-1-item-3.png`}
              alt="image3"
              className={`w-[100px] h-[80px] object-contain`}
            />
            <h5>{InstitutionData.src_Components_Home_Header3__h5_3}</h5>
            <p className={`text-center`}>
              {InstitutionData.src_Components_Home_Header3__p_3}
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header3;
