import React, { useContext, useState } from "react";
import { Auth } from "aws-amplify";
import NavBar from "../Components/NavBar";
import Context from "../Context/Context";
import { useNavigate } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import Country from "../Components/Auth/Country";
import Button2 from "../Components/Utils/Buttons/Button2";
import InstitutionContext from "../Context/InstitutionContext";

const SignUp = () => {
  const InstitutionData = useContext(InstitutionContext).institutionData;

  const [email, setEmail] = useState("");
  const [isCode, setIsCode] = useState(false);
  const [countryCode, setCountryCode] = useState();
  const [confirmationCode, setConfirmationCode] = useState(0);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [err, setErr] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneNumberLoginValid, setIsPhoneNumberLoginValid] = useState(false);
  const UtilCtx = useContext(Context).util;

  const Navigate = useNavigate();

  const form1Validator = () => {
    if (!(email.includes("@") && email.includes("."))) {
      setErr("Enter a Valid Email");
      return false;
    } else {
      setErr("");
      return true;
    }
  };

  const form2Validator = () => {
    if (confirmationCode.length === 0) {
      setErr("Enter a Valid Code");
      return false;
    } else if (password.length < 8) {
      setErr("Password is too Short");
      return false;
    } else if (password !== confirmPassword) {
      setErr("Password Doesn't Match");
      return false;
    } else {
      setErr("");
      return true;
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    try {
      if (isPhoneNumberLoginValid ? true : form1Validator()) {
        if (isPhoneNumberLoginValid)
          await Auth.forgotPassword(`${countryCode}${phoneNumber}`);
        else await Auth.forgotPassword(email);
        setIsCode(true);
        alert("Code sent successfully");
      }
    } catch (e) {
      setErr(e.message);
    }
  };

  const passwordVisibilityChange = () => {
    setPasswordVisible((i) => !i);
  };

  const onConfirmationSubmit = async (event) => {
    event.preventDefault();

    UtilCtx.setLoader(true);

    try {
      if (form2Validator()) {
        if (isPhoneNumberLoginValid)
          await Auth.forgotPasswordSubmit(
            `${countryCode}${phoneNumber}`,
            confirmationCode,
            password
          );
        else await Auth.forgotPasswordSubmit(email, confirmationCode, password);
        UtilCtx.setLoader(false);
        alert("Password Changed");
        Navigate("/login");
      } else {
        UtilCtx.setLoader(false);
      }
    } catch (e) {
      setErr(e.message);
      UtilCtx.setLoader(false);
    }
  };

  const form1 = () => {
    return (
      <form
        className={`w-[50vw] max800:w-[90vw]  max-w-[25rem] shadow-2xl rounded-2xl p-4 flex flex-col items-center `}
        style={{
          backgroundColor:
            InstitutionData.src_pages_ForgotPassword__BgColor_Parent,
        }}
      >
        <h3 className={`text-[1.2rem] font-bold`}>Forgot Password ?</h3>
        <ul className={`flex flex-col items-center`}>
          <li
            className={`flex items-center gap-20 mt-8 max500:flex-col max500:gap-2 max500:items-start pl-2`}
          >
            <input
              className={` w-[19.5rem] mr-[2rem] border-[2px] px-3 py-2  rounded-2 max500:w-[80vw]`}
              style={{
                borderColor:
                  InstitutionData.src_pages_ForgotPassword__Border_Color_1,
              }}
              type="text"
              placeholder="Email" // Add the placeholder here
              value={isPhoneNumberLoginValid ? phoneNumber : email}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (/^\d+$/.test(inputValue)) {
                  if (inputValue.length >= 0 && inputValue.length <= 10) {
                    setIsPhoneNumberLoginValid(true);
                    setPhoneNumber(inputValue);
                  }
                } else {
                  setIsPhoneNumberLoginValid(false);
                  setEmail(inputValue);
                }
              }}
            />
          </li>
          <li
            className={`flex items-center gap-20 mt-8 max500:flex-col max500:gap-2 max500:items-start`}
          >
            {isPhoneNumberLoginValid && (
              <select
                name="countryCode"
                id=""
                value={countryCode}
                className={`w-[19.5rem] mr-[1.5rem] border-[2px] px-[1.5rem] py-2   rounded-2 max500:w-[80vw] mt-6`}
                style={{
                  borderColor:
                    InstitutionData.src_pages_ForgotPassword__Border_Color_2,
                }}
                onChange={(e) => {
                  setCountryCode(e.target.value.toString());
                }}
              >
                {<Country />}
              </select>
            )}
          </li>
        </ul>
        {err && <p className={`text-[0.8rem] mt-2 text-red-500`}>{err}</p>}
        <button
          className={` w-[19.5rem] p-4 py-1 mt-6 mb-3 bg-primary1 text-white rounded-lg max500:w-[80vw]`}
          onClick={onSubmit}
        >
          Send Code
        </button>
      </form>
    );
  };

  const form2 = () => {
    return (
      <form
        className={`w-[30%]  max-w-[28rem] bg-[#FFFFFF] shadow-2xl rounded-2xl p-4 flex flex-col items-center`}
      >
        <h3 className={`text-[1.2rem] font-[700]`}>Sign Up</h3>
        <p className={`mt-3 text-[0.9rem]`}>
          Code Sent to{" "}
          {isPhoneNumberLoginValid ? `+${countryCode}${phoneNumber}` : email}
        </p>
        <ul className={`flex flex-col items-center`}>
          <li className={`flex items-center gap-20 mt-5`}>
            <input
              className={`w-[19.5rem] mr-[2rem] border-[2px] px-3 py-2 rounded-2`}
              type="text"
              placeholder="Enter Code" // Add the placeholder here
              value={confirmationCode === 0 ? "" : confirmationCode}
              onChange={(e) => {
                setConfirmationCode(e.target.value);
              }}
              style={{
                borderColor:
                  InstitutionData.src_pages_ForgotPassword__Border_Color_2,
              }}
            />
          </li>
          <li
            className={`flex items-center gap-20 mt-6 max500:flex-col max500:gap-2 max500:items-start`}
          >
            <input
              className={`w-[19.5rem] mr-[2rem] border-[2px] px-3 py-2  rounded-2 max500:w-[80vw]`}
              style={{
                borderColor:
                  InstitutionData.src_pages_ForgotPassword__Border_Color_2,
              }}
              type="password"
              placeholder="Password" // Add the placeholder here for the password input
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </li>
          <li
            className={`flex items-center gap-20 mt-6 max500:flex-col max500:gap-2 max500:items-start relative`}
          >
            <input
              className={`w-[19.5rem] mr-[2rem] border-[2px] px-3 py-2 rounded-2 max500:w-[80vw]`}
              style={{
                borderColor:
                  InstitutionData.src_pages_ForgotPassword__Border_Color_2,
              }}
              type={!passwordVisible ? "password" : "text"} // Toggle password visibility
              placeholder="Confirm Password" // Add the placeholder here for the confirm password input
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
            {passwordVisible ? (
              <AiFillEye
                onClick={passwordVisibilityChange}
                className={`absolute right-12 `}
                size={"1.25rem"}
              />
            ) : (
              <AiFillEyeInvisible
                onClick={passwordVisibilityChange}
                className={`absolute right-12 `}
                size={"1.25rem"}
              />
            )}
          </li>
        </ul>
        {err && <p className={`text-[0.8rem] mt-2 text-red-500`}>{err}</p>}
        <p
          className="text-black font-[400] text-[0.9rem] mt-3 cursor-pointer"
          onClick={onSubmit}
        >
          Resend Code
        </p>
        <Button2
          data={"Confirm code"}
          fn={onConfirmationSubmit}
          w="19.5rem"
          className={`p-4 py-1 mb-3`}
        />
      </form>
    );
  };

  return (
    <div className={`w-screen min-h-screen bg-authBackground`}>
      <NavBar />
      <div className={`flex flex-col items-center mt-6 text-black`}>
        <h3 className={`text-[2rem]`}>FITNESS</h3>
        <div className={`w-[80%] h-[0.08rem] bg-black flex`}></div>
        <div className={`flex w-[100%] gap-16 justify-center items-end mt-16 `}>
          {!isCode ? form1() : form2()}
          <img
            src={`https://institution-utils.s3.amazonaws.com/${InstitutionData.institutionid}/images/Auth/danceAuth.png`}
            alt="Dance"
            className={`w-[20%] max800:hidden`}
          />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
