import React, { useContext, useEffect, useState } from "react";
import { API } from "aws-amplify";
import "./userprofile.css";
import Context from "../../Context/Context";
import { useNavigate } from "react-router-dom";
import Button2 from "../Utils/Buttons/Button2";
import InstitutionContext from "../../Context/InstitutionContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUserEdit } from "@fortawesome/free-solid-svg-icons";

const UserProfile = ({
  isUserAdd,
  updateUserInList,
  isOpen,
  onClose,
  user,
}) => {
  const InstitutionData = useContext(InstitutionContext).institutionData;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [status, setStatus] = useState("");
  const [balance, setBalance] = useState("");
  const [country, setCountry] = useState(
    user && user.country ? user.country : ""
  );
  const UtilCtx = useContext(Context).util;
  const Ctx = useContext(Context);
  const [cognitoId, setCognitoId] = useState("");
  const Navigate = useNavigate();
  const [lastMonthZPoints, setLastMonthZPoints] = useState("");
  const [currentMonthZPoints, setCurrentMonthZPoints] = useState("");
  // const [cognitoId, setCognitoId] = useState("");
  const formatDate = (epochDate) => {
    const date = new Date(epochDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  console.log("Received user prop:", user);

  useEffect(() => {
    setName(user && (user.userName ? user.userName : ""));
    setEmail(user && (user.emailId ? user.emailId : ""));
    setPhoneNumber(user && (user.phoneNumber ? user.phoneNumber : ""));
    setStatus(user && (user.status ? user.status : ""));
    setBalance(user && (user.balance ? user.balance : ""));
    setCognitoId(user && (user.cognitoId ? user.cognitoId : ""));
    setCountry(user && (user.country ? user.country : ""));
    setLastMonthZPoints(
      user && (user.lastMonthZPoints ? user.lastMonthZPoints : "0")
    );
    setCurrentMonthZPoints(
      user && (user.currentMonthZPoints ? user.currentMonthZPoints : "0")
    );
  }, [user]);

  const onUpdateUser = async (e) => {
    e.preventDefault();

    if (
      !(
        name &&
        email &&
        phoneNumber &&
        status &&
        balance &&
        country &&
        cognitoId
      )
    ) {
      alert("Fill all Fields");
      return;
    }
    if (!name) {
      alert("Fill Name");
      return;
    } else if (!email) {
      alert("Fill email");
      return;
    } else if (!phoneNumber) {
      alert("Fill Phone Number");
      return;
    } else if (!status) {
      alert("Fill Status");
      return;
    } else if (!balance) {
      alert("Fill Balance");

      return;
    }

    UtilCtx.setLoader(true);

    try {
      console.log("started");

      await API.put(
        "user",
        `/admin/update-user/${InstitutionData.institutionid}`,
        {
          body: {
            cognitoId: cognitoId,
            emailId: email,
            userName: name,
            phoneNumber: phoneNumber,
            status: status,
            balance: balance,
            country: country,
            currentMonthZPoints: currentMonthZPoints,
            lastMonthZPoints: lastMonthZPoints,
          },
        }
      );

      alert("User Updated");

      UtilCtx.setLoader(false);

      const userList = Ctx.userList.map((item) => {
        if (item.cognitoId === cognitoId) {
          item.emailId = email;
          item.userName = name;
          item.phoneNumber = phoneNumber;
          item.status = status;
          item.balance = balance;
          item.currentMonthZPoints = currentMonthZPoints;
          item.lastMonthZPoints = lastMonthZPoints;
          item.country = country;
          return item;
        } else {
          return item;
        }
      });

      Ctx.setUserList(userList);

      onClose();
    } catch (e) {
      console.log(e);
      UtilCtx.setLoader(false);
    }
  };

  useEffect(() => {
    if (Ctx.isUserDataLoaded) {
      if (Ctx.userData.userType !== "admin") {
        Navigate("/");
      }
    }
  }, [Ctx, Navigate]);

  const getWhatsappUrl = () => {
    const currentMonth = new Date().toLocaleString("default", {
      month: "long",
    });
    const message = `Payment screenshot for ${currentMonth}`;

    const whatsappUrl = `https://wa.me/${
      InstitutionData.wpNo
    }?text=${encodeURIComponent(message)}`;
    return whatsappUrl;
  };

  const sendReminder = async (cognitoId) => {
    UtilCtx.setLoader(true);

    const pa = InstitutionData.upiId;
    // const am = 10;

    try {
      const res = await API.post(
        "user",
        `/user/send-email/${InstitutionData.institutionid}`,
        {
          body: {
            pa,
            paymentPhoneNumber: InstitutionData.wpNo,
            whatsappUrl: getWhatsappUrl(),
            qrLink: `https://institution-qr-code.s3.amazonaws.com/${InstitutionData.institutionid}/QRCode.jpg`,
            cognitoId,
          },
        }
      );

      alert(res.message);
      UtilCtx.setLoader(false);
    } catch (e) {
      console.log(e);
      UtilCtx.setLoader(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className={`modal`}>
      <div className={`modal-content flex items-center justify-center `}>
        <div className={``}>
          <div
            className={`w-[100%] max1050:w-[100%] max-w-[36rem] rounded-3xl p-3 flex flex-col items-center max536:w-[90%]`}
            style={{
              backgroundColor:
                InstitutionData.src_Components_Home_ProfileUpdate__bgParent,
            }}
          >
            <form className={`mt-6 flex flex-col gap-8 max560:w-full`}>
              {/* Name */}
              <div className="flex flex-row justify-between gap-4 max560:flex-col max560:gap-8">
                <div className="flex flex-col gap-1 justify-center">
                  <label className="ml-2">Name</label>
                  <input
                    required
                    placeholder="Name"
                    className="text-inputTextColor1 bg-inputBgColor1  px-4 py-2 rounded-lg"
                    type={"text"}
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </div>

                <div className="flex flex-col gap-1 justify-center">
                  <label className="ml-2">Email</label>
                  <input
                    className={` text-inputTextColor1 bg-inputBgColor1 px-4 py-2 rounded-lg`}
                    type="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-row justify-between gap-4 max560:flex-col max560:gap-8">
                <div className="flex flex-col gap-1 justify-center">
                  <label className="ml-2">Country</label>
                  <input
                    className="text-inputTextColor1 bg-inputBgColor1 px-4 py-2 rounded-lg"
                    type="text"
                    value={country}
                    onChange={(e) => {
                      setCountry(e.target.value);
                    }}
                  />
                </div>

                <div className={`flex flex-col gap-1 justify-center`}>
                  <label className={`ml-2`}>Phone Number</label>
                  <input
                    className={` text-inputTextColor1 bg-inputBgColor1 px-4 py-2 rounded-lg`}
                    type="text"
                    value={phoneNumber}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                    }}
                  />
                </div>
              </div>

              {/* Attendance and Joining Date */}
              <div
                className={`flex flex-row justify-between gap-4 max560:flex-col max560:gap-8`}
              >
                <div className={`flex flex-col gap-1 justify-center`}>
                  <label className={`ml-2`}>Attendance</label>
                  <div className={`flex items-center justify-center w-[70%]`}>
                    <input
                      className={` bg-inputBgColor1 px-2 py-2 rounded-lg`}
                      type="number"
                      value={currentMonthZPoints}
                      onChange={(e) => {
                        setCurrentMonthZPoints(e.target.value);
                      }}
                    />
                    <p className={`mt-3 mx-2`}>/</p>
                    <input
                      className={` bg-inputBgColor1 px-2 py-2 rounded-lg `}
                      type="number"
                      value={lastMonthZPoints}
                      onChange={(e) => {
                        setLastMonthZPoints(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className={`flex flex-col gap-1 justify-center`}>
                  <label className={`ml-2`}>Joining Date</label>
                  <div
                    className={` bg-inputBgColor1 px-4 py-2 rounded-md`}
                    style={{
                      width: "222px",
                    }}
                  >
                    {formatDate(user.joiningDate)}
                  </div>
                </div>
              </div>

              {/* User Status and Due */}
              <div
                className={`flex flex-row justify-between gap-4 max560:flex-col max560:gap-8`}
              >
                <div className={`flex flex-col gap-1 justify-center`}>
                  <label className={`ml-2`}>User Status</label>
                  <select
                    className={` text-inputTextColor1 bg-inputBgColor1 px-4 py-2 rounded-lg `}
                    style={{
                      width: "222px",
                    }}
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                  >
                    <option value="Active">Active</option>
                    <option value="InActive">InActive</option>
                  </select>
                </div>

                <div className={`flex flex-col gap-1 justify-center`}>
                  <label className={`ml-2`}>Due</label>
                  <input
                    className={` text-inputTextColor1 bg-inputBgColor1 px-4 py-2 rounded-lg`}
                    type="text"
                    value={balance}
                    onChange={(e) => {
                      setBalance(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={`flex flex-col items-center mt-4`}>
                {/* Update Profile Button */}
                <Button2
                  data={
                    <>
                      <FontAwesomeIcon icon={faUserEdit} className="mr-2" />{" "}
                      Update Profile
                    </>
                  }
                  fn={(e) => {
                    console.log("Before onUpdateUser");
                    onUpdateUser(e);
                    console.log("After onUpdateUser");
                    onClose();
                  }}
                  w="8rem"
                />
                {/* Send Invoice Button */}
                <button
                  className="bg-white rounded-lg text-black py-2 mb-2 flex items-center invbut"
                  onClick={(e) => {
                    e.preventDefault();
                    sendReminder(cognitoId);
                  }}
                >
                  <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                  Send Invoice
                </button>
              </div>

              {/* Close Button */}
              <div className={`flex justify-center`}>
                <button onClick={onClose}>Close</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
