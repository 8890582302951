import React from "react";

const Button1 = ({ data, fn, w = "auto", h = "auto" }) => {
  return (
    <button
      className={`sans-serif tracking-wider bg-[#2f2f2f] text-[#e1e1e1] h-[${h}] rounded-lg py-2 px-2 w-[${w}]`}
      onClick={fn}
    >
      {data}
    </button>
  );
};

export default Button1;
