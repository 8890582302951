import { API } from "aws-amplify";
import React, { useState, useMemo } from "react";
import Context from "./Context";

const ContextProvider = (props) => {
  const [isAuth, setIsAuth] = useState(false);
  const [userData, setUserData] = useState({});
  const [loader, setLoader] = useState(false);
  const [upcomingClasses, setUpcomingClasses] = useState([]);
  const [previousClasses, setPreviousClasses] = useState([]);
  const [userList, setUserList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [instructorList, setInstructorList] = useState([]);
  const [streakData, setStreakData] = useState({});
  const [ratings, setRatings] = useState([]);

  const onAuthLoad = async (auth, id) => {
    if (auth) {
      // API.get("user", `/any/products/${id}`)
      //   .then((list) => {
      //     let newList = [];
      //     let tempProduct;
      //     list.forEach((element) => {
      //       if (element.subscriptionType !== "Hybrid") {
      //         newList.push(element);
      //       } else {
      //         tempProduct = element;
      //       }
      //     });
      //     newList = [tempProduct].concat(newList);
      //     setProductList(newList);
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //     setUserList([]);
      //   });

      // API.get("user", `/any/instructor-list/${id}`)
      //   .then((data) => {
      //     console.log(data);
      //     setInstructorList(data);
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //   });

      // try {
      //   const classes = await API.get("user", `/user/upcoming-schedule/${id}`);
      //   setUpcomingClasses(classes);
      // } catch (e) {
      //   setUpcomingClasses([]);
      //   console.log(e);
      // }

      // try {
      //   const classes = await API.get("user", `/user/previous-schedule/${id}`);
      //   setPreviousClasses(classes);
      // } catch (e) {
      //   setPreviousClasses([]);
      //   console.log(e);
      // }

      // try {
      //   const list = await API.get("user", `/admin/profile-list/${id}`);
      //   setUserList(list);
      // } catch (e) {
      //   console.log(e);
      //   setUserList([]);
      // }

      // try {
      //   // Add the API call for fetching streak data
      //   const streakResponse = await API.get("user", `/user/streak-get/${id}`);
      //   setStreakData(streakResponse);
      // } catch (e) {
      //   console.log(e);
      //   setStreakData({});
      // }

      // try {
      //   const response = await API.put("user", `/admin/rating-fetch/${id}`, {
      //     body: {},
      //   });
      //   console.log(response);
      //   setRatings(response);
      // } catch (error) {
      //   console.error("Error fetching ratings:", error);
      // }

      try {
        setLoader(true);
        const res = await Promise.all([
          API.get("user", `/any/products/${id}`),
          API.get("user", `/any/instructor-list/${id}`),
          API.get("user", `/user/upcoming-schedule/${id}`),
          API.get("user", `/user/previous-schedule/${id}`),
          API.get("user", `/admin/profile-list/${id}`),
          API.get("user", `/user/streak-get/${id}`),
          API.put("user", `/admin/rating-fetch/${id}`, {
            body: {},
          }),
        ]);

        let newList = [];
        let tempProduct;
        res[0].forEach((element) => {
          if (element.subscriptionType !== "Hybrid") {
            newList.push(element);
          } else {
            tempProduct = element;
          }
        });
        newList = [tempProduct].concat(newList);
        setProductList(newList);

        setInstructorList(res[1]);
        setUpcomingClasses(res[2]);
        setPreviousClasses(res[3]);
        setUserList(res[4]);
        setStreakData(res[5]);
        setRatings(res[6]);
      } catch (e) {
        console.log(e);
        setProductList([]);

        setInstructorList([]);
        setUpcomingClasses([]);
        setPreviousClasses([]);
        setUserList([]);
        setStreakData([]);
        setRatings([]);
      } finally {
        setLoader(false);
      }
    }
  };

  const onUnauthLoad = async (id) => {
    API.get("user", `/any/products/${id}`)
      .then((list) => {
        console.log(list);
        setProductList(list);
      })
      .catch((e) => {
        console.log(e);
        setUserList([]);
      });
  };

  const setIsAuthFn = (data) => {
    setIsAuth(data);
  };

  const setUserDataFn = (data) => {
    setUserData(data);
  };

  const setLoaderFn = (data) => {
    setLoader(data);
  };

  const setUpcomingClassesFn = (classes) => {
    setUpcomingClasses(classes);
  };

  const setPreviousClassesFn = (classes) => {
    setPreviousClasses(classes);
  };

  const setUserListFn = (list) => {
    setUserList(list);
  };

  const setStreakDataFn = (streakResponse) => {
    setStreakData(streakResponse);
  };

  const checkSubscriptionStatus = useMemo(() => {
    if (userData && userData.userType) {
      const subscriptionType = userData.userType;
      const subscriptionStatus = userData.status;
      if (subscriptionType === "admin") {
        return { borderColor: "green" };
      } else if (subscriptionType === "instructor") {
        return { borderColor: "blue" };
      } else if (
        subscriptionType === "member" &&
        subscriptionStatus === "Active"
      ) {
        return { borderColor: "#1b7571" };
      }
    }
    // Return the default style for non-admin and non-active accounts
    return { borderColor: "red" };
  }, [userData]);

  const ContextData = {
    onAuthLoad: onAuthLoad,
    onUnauthLoad: onUnauthLoad,
    isAuth: isAuth,
    setIsAuth: setIsAuthFn,
    userData: userData,
    setUserData: setUserDataFn,
    util: {
      loader: loader,
      setLoader: setLoaderFn,
    },
    upcomingClasses: upcomingClasses,
    setUpcomingClasses: setUpcomingClassesFn,
    previousClasses: previousClasses,
    setPreviousClasses: setPreviousClassesFn,
    userList: userList,
    setUserList: setUserListFn,
    productList: productList,
    instructorList: instructorList,
    setInstructorList: () => {},
    setProductList: () => {},
    checkSubscriptionStatus: checkSubscriptionStatus,
    streakData: streakData,
    setStreakData: setStreakDataFn,
    ratings: ratings,
    setRatings: () => {},
  };

  return (
    <Context.Provider value={ContextData}>{props.children}</Context.Provider>
  );
};

export { ContextProvider };
