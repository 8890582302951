import React, { useContext, useState } from "react";
import { API, Auth } from "aws-amplify";
import NavBar from "../Components/NavBar";
import Context from "../Context/Context";
import { useNavigate } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import "./Login.css";
// import Country from "../Components/Auth/Country";
import InstitutionContext from "../Context/InstitutionContext";

const Login = () => {
  const InstitutionData = useContext(InstitutionContext).institutionData;
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode] = useState("91");
  const [password, setPassword] = useState("");
  const [err, setErr] = useState("");
  const UtilCtx = useContext(Context).util;
  const UserCtx = useContext(Context);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isPhoneNumberLoginValid, setIsPhoneNumberLoginValid] = useState(true);
  const passwordVisibilityChange = () => {
    setPasswordVisible((i) => !i);
  };

  const Navigate = useNavigate();

  const onSubmit = async (event) => {
    event.preventDefault();

    UtilCtx.setLoader(true);

    try {
      const user = await Auth.signIn(
        `${isPhoneNumberLoginValid ? `+${countryCode}${phoneNumber}` : email}`,
        password
      );

      if (user) {
        const userdata = await API.get(
          "user",
          `/user/profile/${InstitutionData.institutionid}`
        );
        UserCtx.setUserData(userdata);
        UserCtx.setIsAuth(true);
        UtilCtx.setLoader(false);
        alert("Logged In");
        console.log(userdata.status);

        Navigate("/dashboard");
        // if (userdata.status.trim() === "Active") {
        // } else {
        //   Navigate("/subscription");
        // }
      } else {
        setErr(
          `Incorrect ${
            isPhoneNumberLoginValid ? `+${countryCode}${phoneNumber}` : email
          } and password`
        );
        UtilCtx.setLoader(false);
      }
    } catch (e) {
      if (e.toString().split(" code ")[1]?.trim() === "404") {
        console.log("User Not Found");
        alert("You Must Sign Up First and use the same email and password");
        Navigate("/signup?newuser=false");
        setErr("");
      } else {
        setErr(e.message);
      }
      UtilCtx.setLoader(false);
    }
  };

  return (
    <div className={`w-screen min-h-screen bg-authBackground`}>
      <NavBar />
      <div className={`flex flex-col items-center mt-12 text-black`}>
        {/* <h3 className={`text-[2rem]`}>FITNESS</h3>
        <div className={`w-[80%] h-[0.08rem] bg-black flex`}></div> */}
        <div className={`flex w-[100%] gap-16 justify-center items-end mt-28`}>
          <form
            className={`w-[50vw] max800:w-[90vw]  max-w-[28rem] bg-[#FFFFFF] shadow-2xl rounded-2xl p-4 flex flex-col items-center `}
          >
            <h3 className={`text-[1.2rem] font-[700]`}>Login</h3>
            <p className={`text-center font-[500] text-[0.8rem] mb-[0rem]`}>
              Hey, Enter your details to sign in <br /> to your account
            </p>
            <ul className={`flex flex-col items-center`}>
              {/* {isPhoneNumberLoginValid && (
                <select
                  name="countryCode"
                  id=""
                  value={countryCode}
                  className={`w-[19.5rem] mr-[1.5rem] border-[2px] px-[1.5rem] py-2 rounded-2 max500:w-[80vw] mt-6`}
                  style={{
                    borderColor:
                      InstitutionData.src_pages_Login__Border_Color_1,
                  }}
                  onChange={(e) => {
                    setCountryCode(e.target.value.toString());
                  }}
                >
                  {<Country />}
                </select>
              )} */}
              <li
                className={`flex gap-20 mt-8  max500:flex-col max500:gap-2 max500:items-start relative`}
              >
                <div className={`relative`}>
                  <input
                    className={`w-[19.5rem] mr-[1.5rem] border-[2px] px-6 py-2 rounded-2 max500:w-[80vw]`}
                    style={{
                      borderColor:
                        InstitutionData.src_pages_Login__Border_Color_1,
                    }}
                    onC
                    type="text"
                    placeholder="Enter Email"
                    value={isPhoneNumberLoginValid ? phoneNumber : email}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (/^\d+$/.test(inputValue)) {
                        if (inputValue.length >= 0 && inputValue.length <= 10) {
                          setIsPhoneNumberLoginValid(true);
                          setPhoneNumber(inputValue);
                        }
                      } else {
                        setIsPhoneNumberLoginValid(false);
                        setEmail(inputValue);
                      }
                    }}
                  />
                </div>
              </li>
              <li
                className={`flex items-center gap-20 mt-6 max500:flex-col max500:gap-2 max500:items-start relative`}
              >
                <div className={`relative`}>
                  <input
                    className={`w-[19.5rem] mr-[1.5rem] border-[2px] px-[1.5rem] py-2 rounded-2 max500:w-[80vw]`}
                    style={{
                      borderColor:
                        InstitutionData.src_pages_Login__Border_Color_1,
                    }}
                    onC
                    type={passwordVisible ? "text" : "password"}
                    placeholder="Enter Your Password" // Add the placeholder here
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />

                  {passwordVisible ? (
                    <AiFillEye
                      onClick={(e) => {
                        e.stopPropagation();
                        passwordVisibilityChange();
                      }}
                      className={`absolute top-3 right-8 cursor-pointer`}
                      size={"1.25rem"}
                    />
                  ) : (
                    <AiFillEyeInvisible
                      onClick={(e) => {
                        e.stopPropagation();
                        passwordVisibilityChange();
                      }}
                      className={`absolute top-3 right-8 cursor-pointer`}
                      size={"1.25rem"}
                    />
                  )}
                </div>
              </li>
            </ul>
            <p
              className={`text-black font-[600] text-[0.7rem] mt-3 cursor-pointer`}
              onClick={() => {
                Navigate("/forgotpassword");
              }}
            >
              Forgot Password?
            </p>
            {err && <p className={`text-[0.8rem] mt-2 text-red-500`}>{err}</p>}

            <button
              className={`w-[19.5rem] py-2 mt-3 mb-3 bg-primary2 text-white rounded-2 max500:w-[80vw]`}
              onClick={onSubmit}
            >
              Sign in
            </button>
            <p
              className={` text-[0.85rem] text-black cursor-pointer`}
              onClick={() => {
                Navigate("/signup");
              }}
            >
              Don’t have an account?{" "}
              <span className={`font-[500] text-primary2`}>Register Now</span>{" "}
            </p>
          </form>
          <img
            src={`https://institution-utils.s3.amazonaws.com/${InstitutionData.institutionid}/images/Auth/danceAuth.png`}
            alt="Dance"
            className={`w-[20%] max800:hidden`}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
